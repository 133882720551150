@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-5xl text-center font-extrabold mb-6 lg:text-left;
}

h2 {
  @apply text-4xl text-center font-extrabold mb-6 lg:text-left;
}

h3 {
  @apply text-3xl text-center font-extrabold mb-6 lg:text-left;
}

h3 {
  @apply text-2xl text-center font-extrabold mb-6 lg:text-left;
}

h4 {
  @apply text-xl text-center font-extrabold mb-4 lg:text-left;
}

h5 {
  @apply text-lg text-center font-extrabold mb-2 lg:text-left;
}

body {
  font-family: 'Nunito', sans-serif;
}

.ant-table-cell {
  @apply text-xs text-left;
}

.ff-primary {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

/* Map LegenBox */
#legendBox > div {
  background-color: #1c2636;
  border-radius: 8px;
}

#legendBox h4 {
  color: #fff;
}

.table_col-center > .ant-table-column-sorters > span:first-child {
  width: 100%;
  display: inline-block;
  text-align: center;
}

th.table_col-right > .ant-table-column-sorters > span:first-child {
  width: 100%;
  display: inline-block;
  text-align: right;
  padding-right: 0.5rem;
}

td.table_col-right > div:first-child {
  padding-right: 1.25rem;
}
.clickable {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .header-logo {
    width: 85px;
    margin-left: -25px;
  }
}
.ant-layout-sider-trigger {
  background: #e6f7ff;
  color: #1890ff;
}

@media (max-width: 640px) {
  .con-table .ant-table {
  }

  .con-table .ant-pagination {
    background-color: #fff;
    width: 100%;
    left: 90px;
    bottom: 0;
    height: 40px;
    position: fixed;
    justify-content: flex-start;
    margin: 0;
  }

  .con-table .ant-pagination > li {
    margin-top: 5px;
  }
}
